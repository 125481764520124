/* általános beállítások */
a {
	outline: 0;
	text-decoration: none;
	@include transition(all,0.2s,ease);
    
	&:hover {
	}
}
.clear { /* floatoltatott elemek feloldására. használat: közvetlenül az elemek után, <br class="clear"> */
    clear: both;
}
.clearfix{
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
        line-height: 0;
    }
    /* start commented backslash hack \*/
    * html &{ height: 1%; }
    &{ display: block; }
    /* close commented backslash hack */ 
}
.wrap{
    max-width: 1130px;
    margin: 0 auto;
    width: 100%;
}
.vertical_middle{/*ha egy elem van az elemnünkben, akkor azt vertikálisan középre igazítja. amire rákerül, az mozillában a min-height értéket nem figyeli majd, simán height állítandó be. a legjobb működést az @extend .vertical_middle; adja, classként nem mindig szeret működni*/
    display: table;
    
    > *{
        display: table-cell;
        vertical-align: middle;
    }
}

body{
    /*font-family: Ubuntu;*/
    font-weight: normal;
    font-style: normal;
}

/* footer letolásához a beállítások */
html, body, body > .container, div[id^=skeleton]{
    height: 100%;
}

.btn-default{
    @extend .vertical_middle;
    background: blue;//$darkerturquoise;
    color: #FFF;
    font-size: 11px;
    line-height: 11px;
    font-weight: bold;
    height: 55px;
    border: none;
    border-radius: 0;
    transition: all 0.1s;
    text-transform: uppercase;
    text-shadow: none;
    
    &:hover{
        color: #FFF;
        background: darkblue;//$darkerturquoise2;
    }
    &.shadowed{
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2) inset;
        
        &:hover{
            box-shadow: 2px 7px 12px 2px rgba(0, 0, 0, 0.4) inset;
        }
    }
    &.halfbgr{
        background: rgba(0, 154, 167, 0.5);
        
        &:hover{
            background: rgba(2, 137, 148, 0.5);
        }
    }
    &.disabled{
        background: darkblue;//$darkerturquoise2;
        border: none;
    }
    span{
        white-space: normal;
    }
}

.btn-green{
    @extend .btn-default;
    background: green;//$green;
    
    &:hover{
        background: darkgreen;//$darkergreen;
    }
    &.disabled{
        background: darkgreen;//$darkergreen;
    }
}
.btn-orange{
    @extend .btn-default;
    background: orange;//$orange;
    
    &:hover{
        background: darkorange;//$darkerorange;
    }
    &.disabled{
        background: darkorange;//$darkerorange;
    }
}

.btn-grey{
    @extend .btn-default;
    width: 169px;
    height: 39px;
    background: lightgrey;//$grey;
    color: brown;//$verydarkbrown;
    
    &:hover{
        background: grey;//$darkergrey;
        color: darkbrown;//$verydarkbrown;
    }
    &.disabled{
        background: grey;//$darkergrey;
        color: darkbrown;//$verydarkbrown;
    }
}